* {
  font-family: $font-family;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

h1 {
  color: $primary;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  @media screen and (min-width: 768px) {
    font-size: 35px;
    line-height: 45px;
  }
  @media screen and (min-width: 992px) {
    font-size: 45px;
    line-height: 56px;
  }
}

h2 {
  font-family: $font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: $secondary;
  @media screen and (min-width: 768px) {
    font-size: 25px;
    line-height: 35px;
  }
  @media screen and (min-width: 992px) {
    font-size: 30px;
    line-height: 38px;
  }
}

h3 {
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
  color: $secondary;
  @media screen and (min-width: 768px) {
    font-size: 32px;
    line-height: 45px;
  }
  @media screen and (min-width: 992px) {
    font-size: 40px;
    line-height: 50px;
  }
}

p {
  color: $teal-blue;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 1.2rem;
  white-space: pre-line;
  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 30px;
  }
}

a {
  color: $link-color;
}

.pink-text {
  color: $secondary !important;
}

.teal-text {
  color: $teal-blue !important;
}

.primary-text {
  color: $primary !important;
}
.font-family-helvetica {
  font-family: Helvetica;
}
.font-family-montserrat {
  font-family: 'Montserrat', Helvetica;
}
.sub-heading-small-black {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: $teal-blue;
  @media screen and (min-width: 768px) {
    font-size: 22px;
    line-height: 30px;
  }
  @media screen and (min-width: 992px) {
    font-size: 25px;
    line-height: 32px;
  }
}
.sub-heading {
  font-weight: 200;
  font-size: 18px;
  line-height: 25px;
  color: $teal-blue;
}

.gray-text {
  color: $label-color;
}

.sub-heading-small {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: $secondary;
  @media screen and (min-width: 768px) {
    font-size: 25px;
    line-height: 30px;
  }
  @media screen and (min-width: 992px) {
    font-size: 27px;
    line-height: 32px;
  }
}

.black-small-sub-heading {
  color: $black;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01px;
  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: 25px;
  }
}

.paragraph-small {
  font-size: 12px;
  line-height: 15px;
  color: $black;
}

.paragraph-x-small {
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: $black;
}

.contact-us-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $contact-us;
  @media screen and (min-width: 768px) {
    font-size: 13px;
    line-height: 16px;
  }
}

.z-index--1 {
  z-index: -1;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.box-shadow {
  box-shadow: 0px 2.25px 2.25px rgba(0, 0, 0, 0.25);
}

.error-border {
  border-color: red !important;
}
.w-90 {
  width: 90%;
}
.ml-5 {
  margin-left: 3rem !important;
}
.ml-2 {
  margin-left: 0;
  @media screen and (min-width: 768px) {
    margin-left: 50px;
  }
}
.time-frame-selector-container {
  display: flex;
  justify-content: center; /* This will align the 'ALL' button to the right */
}

.time-frame-buttons {
  display: flex;
  justify-content: center; /* This will center the time frame buttons */
  flex: 1; /* This will allow the buttons to take up all available space except for the 'ALL' button */
}

.time-frame-selector-container button {
  background-color: #f3efef;
  color: #6b6868;
  border: none;
  padding: 5px 15px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.time-frame-selector-container button.active {
  background-color: $primary;
  color: #f3efef;
}

.all-button {
  margin-left: 15px;
}

.parent-div {
  background-color: white;
  min-height: 670px;
}
.parent-div-for-mobile-view {
  background-color: white;
  min-height: 570px;
}

.hide {
  opacity: 0;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  animation: 1s ease-out 0s 1 slideInFromRight;
}

.investment-toggle-container {
  display: flex; /* Use flexbox to align items in a row */
  align-items: center; /* Center-align items vertically */
  padding: 10px; /* Some padding around the content */
}
/* Styling for each span */
.investment-menu-item {
  margin-right: 20px; /* Space between items */
  font-family: Arial, sans-serif; /* Font style */
  cursor: pointer; /* Cursor as pointer to indicate clickability */
}
/* Styling for the 'Arrange' label */
.investment-menu-selected {
  font-weight: bold; /* Bold font for the title */
}

.text-select-transparent {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

/* src/styles/GreetingCard.css */
.greeting-card {
  background-color: #fff; /* Assuming an off-white background */
  padding: 10px 20px; /* Adjust padding to fit your design */
  border-radius: 25px; /* Adjust border-radius for rounded edges */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: adds subtle shadow */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif; /* Choose a bolder font if needed */
  font-size: 14px; /* Adjust font size */
  // font-weight: bold; /* Make font bold */
  color: #333; /* Slightly darker text for better readability */
  // position: fixed;
}

.greeting-emoji {
  font-size: 24px; /* Larger size for the emoji */
  margin-right: 10px; /* Space between emoji and text */
}

.summary-card {
  background-color: #f0fdf4; /* Light green background, adjust the color as needed */
  padding: 10px 20px;
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds subtle shadow */
  text-align: center;
  font-family: 'Arial', sans-serif; /* Stylish, readable font */
  color: #2f855a; /* Dark green text color */
}

.summary-card p {
  margin: 0;
  font-size: 16px;
}

.values {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space between amount and percentage */
}

.amount {
  font-size: 24px;
  font-weight: bold; /* Bold for emphasis */
}

.percentage {
  font-size: 20px;
  color: #38a169; /* Slightly different shade of green for visibility */
}

.metric-card {
  background-color: #fff; /* White background */
  border-radius: 25px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 20px;
  text-align: center;
  margin: 10px;
  width: 150px; /* Fixed width for each card */
  box-sizing: border-box; /* Includes padding and border in the element's width and height */
}

.metric-label {
  font-size: 10px;
  color: #666; /* Gray color for the label */
  margin-bottom: 5px;
}

.metric-value {
  font-size: 10px;
  font-weight: bold;
  color: #333; /* Darker color for the value */
  margin-bottom: 5px;
}

.metric-subtext {
  font-size: 8px;
  color: #999; /* Lighter gray for subtext */
}

.scroll-arrow {
  font-size: 24px; /* Adjust size as needed */
  text-align: center;
  cursor: pointer;
  padding: 10px;
  display: flex;
  justify-content: center; /* Center horizontally in the container */
  user-select: none; /* Prevent text selection */
}

.scroll-arrow:hover {
  color: #555; /* Change color on hover */
}
.mt-100 {
  margin-top: 100px;
}

.property-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  padding: 10px;
  justify-content: center;
}

.property-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  text-align: center;
}

.property-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.property-info {
  font-size: 14px;
  color: #333;
}

.property-value {
  font-weight: bold;
  display: block; /* Makes the value appear on a new line */
  color: #007bff; /* Example blue color for the value */
}

@media (max-width: 1199px) {
  /* Adjusts the breakpoint under which the navbar would normally collapse */
  .navbar-nav {
    display: flex !important; /* Forces the nav to always be flex */
    flex-direction: row !important; /* Ensures items are in a row */
  }
  .navbar-toggler {
    display: none; /* Hides the navbar toggler */
  }
  .navbar-collapse {
    display: flex !important; /* Ensures that the navbar items are always displayed */
    flex-basis: auto !important;
  }
}

@media (max-width: 768px) {
  /* Adjusts font size for screens smaller than 768px */
  .nav-link {
    font-size: 8px; /* Smaller font size for mobile devices */
  }
}

@keyframes fadeInOut {
  0%,
  80% {
    opacity: 1; /* Visible from the start until the 3-second mark */
  }
  100% {
    opacity: 0; /* Becomes invisible quickly at the end */
  }
}

.starting-amount-bordered-div {
  border: 1px solid #7c1e72;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.borrowed-amount-bordered-div {
  border: 1px solid #ff318c;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  flex-grow: 1;
}
.reinvested-amount-bordered-div {
  border: 1px solid #db69d7;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  flex-grow: 1;
}
.starting-amount-gain-bordered-div {
  border: 1px solid #feb7ff;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  flex-grow: 1;
}
