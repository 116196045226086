.item-container {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px;
  background-color: $white-light;
}

.item {
  background-color: $white;
  padding: 8px;
  border: 1px solid $white-light;
  border-radius: 4px;
  margin: 4px;
  cursor: pointer;
  user-select: none;
}
.selected-item {
  background-color: $primary;
  padding: 8px;
  border: 1px solid $primary;
  border-radius: 4px;
  margin: 4px;
  cursor: pointer;
  user-select: none;
}

.item:hover {
  background-color: $primary;
}
.drop-header {
  font-weight: bold;
  margin-bottom: 8px;
}
.file-input-container {
  position: relative;
  display: inline-block;
  /* Add styles for the container, like background color, padding, and border */
  background-color: $primary;
  color: $white;
  padding: 10px 20px;
  border: 1px solid $primary;
  border-radius: 10px;
  cursor: pointer;
}

/* Hide the default file input */
.hidden-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-input-label {
  /* Add styles for the label, like text alignment */
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
}
.server-image-tile {
  width: calc(50% - 10px); // 50% width with spacing in between
  height: 100px; // Adjust the height to display the image properly
  float: left;
}
.input-div {
  width: calc(50% - 10px); // 50% width with spacing in between
  height: 100px;
  float: left// Adjust the height to match other tiles;
}

//classes for buttons and image text
.selection-button {
  position: absolute;
  width: 20%; // 50% width with spacing in between
  height: 50;
  left: 80%;
}
.logo-text {
  font-size: 12px; /* Adjust the font size as needed */
}
.selected {
  border: 2px solid $highlight; /* Change the border style as needed */
  background-color: $white-light; /* Change the background color as needed */
}
