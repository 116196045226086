/* MyKanbanBoard.css */

.card-color-red {
  background-color: #FFCCCC !important;
  border-left: 6px solid #FFCCCC;
}


.card-color-green {
  background-color: #CCFFCC !important;
  border-left: 6px solid #CCFFCC;
}

.card-color-blue {
  background-color: #CCCCFF !important;
  border-left: 6px solid #CCCCFF;
}
.card-color-yellow {
  background-color: #FFFFCC !important;
  border-left: 6px solid #FFFFCC;
}

.lane-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Other styles */
}

.lane-remove {
  cursor: pointer;
  /* Other styles */
}
.react-kanban-card {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  background: white;
}
.react-kanban-card-mini {
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  background: white;
}

.react-kanban-card__image {
  width: 100%;
  height: 140px; // Adjust height as needed
  object-fit: cover;
}

.react-kanban-card__content {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.react-kanban-card__title {
  font-weight: bold;
}

.react-kanban-card__remove {
  cursor: pointer;
  align-self: flex-end;
}

.property-container {
  display: flex;
  flex-direction: column;
  // margin-left: auto;
  // margin-right: auto;
  max-width: 600px; /* Adjust the width as needed */
  padding: 20px;
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.property-header h1 {
  margin: 0;
  padding-bottom: 20px;
  border-bottom: 2px solid #EEE;
  font-size: 24px;
}

.property-body {
  padding-top: 20px;
}

.property-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.property-row label {
  min-width: 100px; /* Ensures alignment of labels */
  font-weight: bold;
}

.property-row input,
.property-row textarea {
  flex: 1;
  margin-left: 10px;
  padding: 5px;
  border: 1px solid #CCC;
  border-radius: 4px;
}

.property-row button {
  margin-left: 10px;
  padding: 5px 15px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.property-row button:hover {
  background-color: #0056b3;
}


// /* Add more colors as needed */