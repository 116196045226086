.italic-text {
  font-style: italic;
}

.pl-0 {
  padding-left: 0px;
}

.w-90 {
  width: 92%;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-8 {
  font-size: 8px;
}

.mt-200 {
  margin-top: 220px;
}

.mt-25vh {
  margin-top: 25vh;
}

.mt-n200 {
  margin-top: -250px;
}

.-mt-20 {
  margin-top: -20px;
}
.mt-80{
  margin-top:80px;
  margin-bottom: 40px;
}
.mt-150 {
  margin-top: 150px;
}
.zoom-controls {
  display: flex;
  flex-direction: column; /* Default layout */
  align-items: center;
  gap: 10px; /* Space between buttons */
}

@media (max-width: 768px) {
  .zoom-controls {
    flex-direction: row; /* Change to row layout for smaller screens */
    justify-content: center;
    flex-wrap: wrap; /* Allow wrapping if necessary */
  }
}
/* Default styles for large screens */
/* Default styles for larger screens (tablets, laptops, etc.) */
.maps-container {
  display: flex;
  flex-direction: row; /* Default: Maps side by side */
  justify-content: center; /* Center maps in the row */
  gap: 20px; /* Space between maps */
  position: relative;
}

.overlay-enabled {
  position: relative;
  height: 300px; /* Adjust height for the overlay */
  width: 100%; /* Ensure container takes full width */
}

.map-item {
  width: 100%;
  height: 300px;
  border: 1px solid black;
}

.map-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .maps-container {
    flex-direction: column; /* Stack maps vertically for small screens */
   justify-content: flex-start; /* Align maps to the top */
    align-items: center;/* Align maps to the top */
  }

  .map-overlay {
    position: relative; /* For small screens, the overlay stays where Map 1 is */
    width: 100%; /* Ensure full width for small screens */
    height: auto; /* Adjust height dynamically */
  }
}


.hidable-zoom-controls-small-screen {
  margin-top: 20px;
  display: block; /* Default is visible */
}

@media (min-width: 768px) {
  .hidable-zoom-controls-small-screen {
    display: none; /* Hide on medium and larger screens */
  }
}
.location-search-container {
  display: flex;
  flex-direction: row; /* Default for larger screens (side by side) */
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .location-search-container {
    flex-direction: column; /* Stack vertically for smaller screens */
    gap: 10px; /* Adjust gap for smaller screens */
    align-items: center; /* Center align vertically stacked boxes */
  }
}
.location-search-box {
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 35%;
}

@media (max-width: 768px) {
  .location-search-box {
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px; /* Make full width on smaller screens */
  }
}
