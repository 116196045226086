.contact-form-container {
  width: 75%;
  @media screen and (min-width: 992px) {
    width: 50%;
  }
}

.back-button {
  cursor: pointer;
}

.phoneField {
  // color: $input-text !important;
  background-color: transparent !important;
}

.PhoneInputInput {
  color: $input-text;
  border: none !important;
  background: transparent !important;
}

.phoneField::placeholder {
  color: $input-text !important;
}

.PhoneInputInput:focus {
  outline: none !important;
  border: none !important;
  border-color: transparent !important;
}
