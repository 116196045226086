.custom-fields-border {
  border-radius: 8px;
  border: 1px solid #a1c8cd;
}

.clipboard-container {
  word-break: break-word;
  border-radius: 10px;
  background-color: rgba(240, 240, 317, 1) !important;
    position: relative;
}
.close-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  font-size: 1.5rem;
  color: #000; /* Adjust color as needed */
}
.share-url-text {
  font-size: 20px;
  color: $teal-blue;
  word-break: break-word;
}

.clipboard-icon {
  top: 7px;
  right: 10px;
  cursor: pointer;
}
