.blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(87, 85, 85, 0.685); /* Adjust the background color and opacity as needed */
  backdrop-filter: blur(8px); /* Adjust the blur amount as needed */
  z-index: 1000;
}

.relative {
  position: relative;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 8px;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Style for the chip-like button */
.chip-button {
  display: inline-block;
  padding: 8px 12px;
  margin: 4px;
  border: 1px solid #ccc;
  border-radius: 16px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover effect */
.chip-button:hover {
  background-color: $primary;
}

/* Active/focus effect */
.chip-button:active,
.chip-button:focus {
  outline: none;
  background-color: #d0d0d0;
}
//
