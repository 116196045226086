// feedback section

.thumbs-up-down-img {
  width: 20px;
  height: 20px;
  opacity: 0.6;
}

.thumbs-up-down-img:hover {
  opacity: 1;
}
