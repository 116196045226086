.container-for-qsp {
  background-color: $qsp-white;
  max-width: 1000px;
}

// tooltip styles
.qsp-label-font {
  font-size: 12px;
}

.sliding-toggle-label {
  color: #6d6d80;
  font-size: 14px;
}
