.custom-secondary-button {
  background: linear-gradient(180deg, $secondary 0%, $primary 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 16px;
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 16.5px;
  line-height: 33px;
  color: $white;
  padding: calc(0.5rem + 1px) calc(1.2rem + 1px) !important;
}
.custom-secondary-button.disabled {
  background-color: grey; /* Greyish background */
  color: white; /* White text for contrast */
  cursor: not-allowed; /* Change cursor to not-allowed */
  opacity: 0.6; /* Slightly transparent */
}
.btn-blue {
background: #447179;
}

.custom-selected-div {
  position: relative;
  background: $white 100%;
  border-radius: 16px;
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 16.5px;
  line-height: 33px;
  color: $secondary;
  padding: calc(0.5rem + 1px) calc(1.2rem + 1px) !important;
}

.custom-selected-div::after {
  content: '\2713'; /* Unicode character for a checkmark */
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  color: green; /* Change this to your desired tick color */
}

.custom-primary-button {
  background-color: $white !important;
  font-family: $font-family !important;
  border-radius: 6.8px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1rem;
  border: 2px solid $primary !important;
  color: $primary !important;
  font-size: 10px;
  line-height: 16px;
  padding: 3px 9px !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  @media (min-width: 768px) {
    font-size: 12px;
    line-height: 20px;
    padding: 4px 10px !important;
  }
}

.custom-primary-button:focus {
  background-color: $primary !important;
  color: $white !important;
  border: 2px solid $primary !important;
}

.custom-primary-button:hover {
  background-color: $primary !important;
  color: $white !important;
  border: 2px solid $primary !important;
}

.input-field-button {
  font-size: 20px;
  padding-block: 2px !important;
  padding-inline: 10px !important;
}

.clipboard-btn { 
  border-radius: 11px;
  background: linear-gradient(180deg, $secondary 0%, $primary 100%);
}