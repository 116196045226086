.custom-slider {
  .rs-slider-bar {
    background-color: $tertiary-green;
    &:hover {
      background-color: $tertiary-green;
    }
  }
  .rs-slider-graduator > ol > li {
    background-color: $secondary-green;
    &::before {
      border: 2px solid $secondary-green;
      background-color: $secondary-green;
    }
    &::after {
      border: 2px solid $secondary-green;
      background-color: $secondary-green;
    }
  }
  .rs-slider-graduator > ol > li.rs-slider-pass::before {
    border-color: $green;
  }
  .rs-slider-graduator > ol > li.rs-slider-active::before {
    visibility: visible;
  }
  .rs-slider-graduator > ol > li:last-child::after {
    background-color: $secondary-green;
    border: 2px solid $secondary-green;
    &::before {
      background-color: $secondary-green;
    }
    &::after {
      background-color: $secondary-green;
    }
  }

  .rs-slider-progress-bar {
    background-color: $green;
  }
  .rs-slider-handle {
    top: -6px;
    background-color: transparent;
    text-align: center;
    padding: 3px;
    margin-left: -16px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 0 0 8px rgba(9, 65, 12, 0.25);
    }

    &::before {
      display: none;
    }
  }

  &.rs-slider-dragging .rs-slider-handle,
  &.rs-slider-dragging .rs-slider-handle:hover {
    box-shadow: none;
    transform: scale(1.2);
  }
}


// slider for waystone
.waystone-custom-slider {
  .rs-slider-bar {
    background-color:$beige-for-slider;
    &:hover {
      background-color: $light-beige;
    }
  }
  .rs-slider-graduator > ol > li {
    background-color:$dark-beige;
    &::before {
      border: 2px solid  $light-beige;
      background-color: $light-beige;
    }
    &::after {
      border: 2px solid$dark-beige;
      background-color:$dark-beige;
    }
  }
  .rs-slider-graduator > ol > li.rs-slider-pass::before {
    border-color:$dark-beige;
  }
  .rs-slider-graduator > ol > li.rs-slider-active::before {
    visibility: visible;
  }
  .rs-slider-graduator > ol > li:last-child::after {
    background-color:$dark-beige;
    border: 2px solid$dark-beige;
    &::before {
      background-color:  $light-beige;
    }
    &::after {
      background-color:$dark-beige;
    }
  }

  .rs-slider-progress-bar {
    background-color:$dark-beige;
  }
  .rs-slider-handle {
    top: -6px;
    background-color: transparent;
    text-align: center;
    padding: 3px;
    margin-left: -16px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 0 0 8px rgba(9, 65, 12, 0.25);
    }

    &::before {
      display: none;
    }
  }

  &.rs-slider-dragging .rs-slider-handle,
  &.rs-slider-dragging .rs-slider-handle:hover {
    box-shadow: none;
    transform: scale(1.2);
  }
}